import React, { useState } from "react";
import PropTypes from "prop-types";
import iconTVG from "@app/Resources/Images/icono_TVG.png";
import { TVG } from "../../Utils/Constants";
import VideoType from "../../../../detail/src/Pages/DetailPage/VideoType";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

const GalleryCardComponentV2 = (props) => {
  const { width, height, image, contentType, tags, containerIsHover } = props;
  const [isHover, setIsHover] = useState(false);
  const [isHoverPlayIcon, setIsHoverPlayIcon] = useState(false);

  const getCardIsHover = () => {
    return isHover || containerIsHover;
  };

  const galleryCardStyle = {
    margin: "0.2rem",
    paddingLeft: 0,
    listStyle: "none",
    width: `${width}px`,
    height: `${height}px`,
    position: "relative",
    borderRadius: "0.5rem",
    overflow: "hidden",
    border: getCardIsHover() ? "2px solid var(--hoverColor)" : '',
  };

  const backgroundImageStyle = {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "right center",
    opacity: getCardIsHover() ? 0.25 : 1,
    zIndex: -1,
  };

  const playIconContainerStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const playIconStyle = {
    fontSize: '2rem',
    color: isHoverPlayIcon ? 'var(--hoverColor)' : 'var(--defaultButtonTextColor)',
  }

  const subtitle = {
    type: {
      name: contentType,
    },
  };

  return (
    <div
      style={galleryCardStyle}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {getCardIsHover() && <div style={playIconContainerStyle} >
        <PlayCircleOutlineIcon style={playIconStyle} onMouseEnter={() => setIsHoverPlayIcon(true)} onMouseLeave={() => setIsHoverPlayIcon(false)}/>
      </div>}
      <div style={backgroundImageStyle}></div>
      {tags?.size !== 0 && tags?.toArray().includes(TVG) && <img className="iconTVG" src={iconTVG} alt={"iconTVG"} />}
      <VideoType subtitle={subtitle} />
    </div>
  );
};

GalleryCardComponentV2.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
};

export default GalleryCardComponentV2;

import React, {useRef, useState} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import InfoIcon from "@material-ui/icons/Info";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

import { MULTITENANT_CONSTANTS, TENANT } from "../../../Resources/Multitenant/tenantConstants";
import {URL_PLAYER, URL_VIDEODETAIL} from "../../Routes/Location";
import { ACCESS_PLAY, PLAYLIST_URL_VALUE } from "../../../../../detail/src/Pages/DetailPage/PlaylistConstants";
import VideoType from "../../../../../detail/src/Pages/DetailPage/VideoType";
import VideoTag from "../../../../../detail/src/Pages/DetailPage/VideoTag";
import {
    actionTypeContainer,
    getContentCodeByLanguage,
    renderImageUrl,
    getWidgetTitle,
    getPreviewUrl
} from "../../../Utils/utils";
import style from "./style.module.css";
import {useSelector} from "react-redux";

const Slide = ({ w, setRef, defaultBanner, trigger, clickCallback, renderMobileVersion }) => {
  const { t } = useTranslation();
  const limitOfTags = 4;
  const [previewUrl, setPreviewUrl] = useState(null);
  const timeoutRef = useRef(null);
    const [isHoveredTime, setIsHoveredTime] = useState(false);

    const handleMouseEnter = async () => {
        timeoutRef.current = setTimeout(async () => {
            if (!previewUrl) {
                const url = await getPreviewUrl(w.id);
                    setPreviewUrl(url);
            }
            setIsHoveredTime(true);
        }, 1000);
    };

  const handleMouseLeave = () => {
      if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
      }
      setIsHoveredTime(false);
  };

  const showPreviews = useSelector((state) => state.commonState.previews);

  const translatedTitle = getWidgetTitle(w);


  return (
    <Link
      style={MULTITENANT_CONSTANTS[TENANT].fullWidth ? { cursor: "pointer" } : { cursor: "default" }}
      to={{
        pathname: URL_VIDEODETAIL + "/" + w.id,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={style.playlistHighlightItem} key={w.id}>
        {translatedTitle !== " " && !MULTITENANT_CONSTANTS[TENANT].fullWidth && (
          <div className={style.overContainerHome}>
            <div className={style.textHome}>
              <div className={style.highlightInfoContainer}>
                {!!w.category && <span className={style.xenero}>{getContentCodeByLanguage(w.category)}</span>}
                {w.publish_date && (
                  <span className={style.data}>{moment(w.publish_date, "YYYY-MM-DD").format("DD MMM YYYY")}</span>
                )}
                {w.seasons && <span className={style.temporadas}>{t("ficha.seasons", { seasons: w.seasons })}</span>}
                {w.rating && <span className={style.idade}>{t("ficha.rating", { rating: w.rating.code })}</span>}
              </div>
              <VideoType subtitle={w} />
              <div className={style.titleHighlight}>{translatedTitle} {w.video?.url}</div>
              <div className={style.highlightButtonsContainer}>
                {w.video?.url && (
                <Link
                  to={{
                    pathname: trigger === ACCESS_PLAY && URL_PLAYER + "/" + w.id,
                  }}
                  className={style.buttonSeeVideo}
                  onClick={clickCallback ? () => clickCallback(w.id) : ""}
                >
                  <PlayCircleOutlineIcon className={style.infoIcon} />
                  {t("content.see.now")}
                </Link>
                )}
                <Link
                  to={{
                    pathname: URL_VIDEODETAIL + "/" + w.id,
                  }}
                  className={style.buttonSeeDetails}
                >
                  <InfoIcon id={style.infoIcon} />
                  {t("content.more.information")}
                </Link>
              </div>
              {!!w.tags?.length && (
                <div className={style.highlightTagsContainer}>
                  {MULTITENANT_CONSTANTS[TENANT].showVideoTags ? (
                    <>
                      <LocalOfferIcon className={style.tagIcon} />
                      {w.tags?.map((t, index) => {
                        return index < limitOfTags ? (
                          <VideoTag tag={t} key={w.tag + "-" + index} />
                        ) : (
                          index === limitOfTags && "..."
                        );
                      })}
                    </>
                  ) : (
                    w.subtitle
                  )}
                </div>
              )}
                {w.video?.url && (
              <Link
                to={{
                  pathname: actionTypeContainer(trigger).ROUTE + "/" + w.id,
                }}
                className={style.buttonAccess}
                onClick={clickCallback ? () => clickCallback(w.id) : ""}
              />
                )}
            </div>
          </div>
        )}
        {w.background === null && w.poster === null && (
          <div className={`${style.playlistHighlight} ${translatedTitle !== " " && style.playlistHighlightCover}`}>
            <img
              className={style.playlistHighlight}
              src={defaultBanner}
              alt={translatedTitle}
              ref={setRef(w.title + PLAYLIST_URL_VALUE)}
            />
          </div>
        )}
        {(w.background || w.banner) && (
            <div
                className={`${style.playlistHighlight} ${
                    !MULTITENANT_CONSTANTS[TENANT].fullWidth ? style.playlistHighlightCover : ""
                } ${style.playlistHighlightHover}`}
            >
                {isHoveredTime && showPreviews && !!previewUrl ?
                    <video
                        className={style[MULTITENANT_CONSTANTS[TENANT].classHeight]}
                        alt={translatedTitle}
                        muted
                        autoPlay
                        loop
                        onError={(e) => {
                                e.target.pause();
                                e.target.src = "";
                        }}
                    >
                        <source src={previewUrl} type="video/webm" />
                        <source src={previewUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    :
                    <img
                        className={style[MULTITENANT_CONSTANTS[TENANT].classHeight]}
                        src={
                            !!renderMobileVersion && !!w.banner
                                ? renderImageUrl(w.banner)
                                : !!w.background
                                    ? renderImageUrl(w.background)
                                    : !!w.banner
                                        ? renderImageUrl(w.banner)
                                        : defaultBanner
                        }
                        alt={translatedTitle}
                        ref={setRef(w.title)}
                    />
                }
            </div>
        )}
      </div>
    </Link>
  );
};

export default Slide;

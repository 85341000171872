import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import { useKeycloak } from "@react-keycloak/web";

import "./Search.css";
import AlertNotification from "../../../../app/src/Components/GUI/Alert/Alert";
import { SearchContext } from "../../Context/SearchContext";
import SearchEmpty from "../../../../app/src/Resources/Images/empty.png";
import VideoType from "../../../../detail/src/Pages/DetailPage/VideoType";
import { turnOffLoadingActionCreator } from "../../../../app/src/actions/commonActions";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import {URL_VIDEODETAIL} from "../../../../app/src/Components/Routes/Location";
import { logUserAnalyticService } from "../../../../app/src/services/services";
import { TENANT } from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {
  PAGE_ID_KEY,
  DETAIL_VALUE,
  PAGE_VIEW_ACTION,
  SEARCH_VALUE,
} from "../../../../app/src/analyticsConstants/analyticsConstants";
import {USER_ANONYMOUS} from "../../../../home/src/Pages/Home/HomeComponentConstants";
import {renderImageBannerData} from "../../../../app/src/Utils/utils";

let updateText = null;
let text;

const Search = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const {scrollRef} = props;
  const userToken = keycloak?.token;

  const [widgets, setWidgets] = useState([]);
  const [error, setError] = useState(false);

  const searchInput = document.getElementById("suggestions-autocomplete");
  const userData = useSelector((state) => state.commonState.userData);

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
    scrollRef?.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  };

  useEffect(() => {
    turnOffLoading();

    !!userData.username && logUserAnalyticService(
      userData.appname,
      userData.username,
      PAGE_VIEW_ACTION,
      PAGE_ID_KEY,
      SEARCH_VALUE,
      userToken
    );

    return () => {
      if (updateText) {
        updateText("");
      }
    };
  }, []);

  useEffect(() => {
    if (searchInput !== null) {
      searchInput.focus();
    }
  }, [searchInput]);

  const selectedVideo = (id) => {
    const username = !!userData.username ? userData.username : USER_ANONYMOUS;
    const userToken = keycloak?.token;

    !!username && logUserAnalyticService(
      TENANT,
      username,
      PAGE_VIEW_ACTION,
      PAGE_ID_KEY,
      DETAIL_VALUE,
      userToken,
      id
    );
  };

  const searchText = useSelector((state) => state.commonState.searchText);

  return (
    <div className="Search">
      <SearchContext.Consumer>
        {(searchContext) => {
          text = searchContext.text;
          setError(searchContext.error);
          updateText = searchContext.updateText;
        }}
      </SearchContext.Consumer>

      {text && (
        <SearchContext.Consumer>
          {(searchContext) => {
            setWidgets(searchContext.listWidgets);
          }}
        </SearchContext.Consumer>
      )}
      {widgets && (
        <>
          {widgets.length !== 0 && (
            <span className="Results">
              <div className="clarifyLayer">
                <div className="resultsText">
                  {t("results", { text: searchText })}
                </div>
              </div>
            </span>
          )}
          {widgets.length === 0 && !props.error && (
            <div className={"contentSearch"}>
              <img
                className="NoResultsImage"
                src={SearchEmpty}
                alt="SearchEmpty"
              />
              <div className="NoResultsText1">{t("search.find")}</div>
              <div className="NoResultsText2">{t("search.find2")}</div>
            </div>
          )}
          {error && <AlertNotification />}
        </>
      )}

      <div className="PlaylistContainerSearch">
        <Row>
          {widgets &&
            widgets.map((w) => (
              <Col xs="12" sm="12" md="6" lg="" className="SearchVideo">
                <div className="SearchImg">
                  <Link
                    to={URL_VIDEODETAIL + "/" + w.id}
                    className="PlaylistItemSearch"
                    key={w.title}
                    onClick={() => selectedVideo(w.id)}
                  >
                    <img
                      className="PlaylistItemImageSearch"
                      src={renderImageBannerData(w)}
                      alt={w.title}
                    />
                    <div className="PlaylistItemTitleSearch">{w.title}</div>
                  </Link>
                  <VideoType subtitle={w} />
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </div>
  );
};

export default WithAuthorization(Search);

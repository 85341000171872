import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {URL_HOME, URL_LOGIN} from "../../../../../app/src/Components/Routes/Location";
import {Link} from "react-router-dom";

const RegisterNavigationComponent  = ({handlerBeforePage, handlerNextPage, finish, followButtonDisabled, noNext = false}) => {
    const { t } = useTranslation();

    return <div className={"registerNavigation"}>
            <div className={!noNext ? "buttonGroupNavigation" : "buttonGroupNavigation noNext"}>
                <div className={"button-secondary button"} onClick={handlerBeforePage}>
                    {
                        !handlerBeforePage ?
                            <Link to={URL_LOGIN} className={"link-secondary"}>{
                                !!finish ? t('register.gotToLogin')  : t('register.cancelButton')
                            }</Link>
                            : t('register.before')
                    }
                </div>
                {!noNext &&
                    <div className={!followButtonDisabled ? "button-primary button" : "button-primary button disabled"}
                         onClick={!followButtonDisabled ? handlerNextPage : ''}>
                        {t('register.next')}
                    </div>}
            </div>

        <div className={"subTitle"}>
            <Link to={`${URL_LOGIN}${URL_HOME}`}>{t('register.enterWithoutRegister')}</Link>
        </div>

    </div>
}

export default RegisterNavigationComponent;

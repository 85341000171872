import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { SearchProvider } from "../../../../../../../search/src/Context/SearchProvider";
import { SearchContext } from "../../../../../../../search/src/Context/SearchContext";
import {URL_SEARCH} from "../../../../../Components/Routes/Location";
import "./SearchBar.css";
import {
  TEXT_SPLIT_LENGTH,
  DEFAULT_SEARCH_LIMIT,
  DEFAULT_SEARCH_PAGE,
  DEFAULT_SEARCH_SORTING,
  SEARCH_MIN_CHARS,
} from "./SearchBarConstants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from "react-redux";
import {
  saveSearchTextActionCreator,
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator
} from "../../../../../actions/commonActions";
import { getSearchContentsService, logUserAnalyticService } from "../../../../../services/services";
import { TENANT } from "../../../../../Resources/Multitenant/tenantConstants";
import { PAGE_ID_KEY, PAGE_VIEW_ACTION, SEARCH_VALUE } from "../../../../../analyticsConstants/analyticsConstants";
import { USER_ANONYMOUS } from "../../../../../../../home/src/Pages/Home/HomeComponentConstants";

const SearchBar = (props) => {
  const { cancel, focus } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const userToken = keycloak?.token;

  const { updateText } = useContext(SearchContext);
  const { updateWidgets } = useContext(SearchContext);

  const userData = useSelector((state) => state.commonState.userData);
  const passwordMode = useSelector((state) => state.commonState.passwordMode);

  let error = false;

  const [text, setText] = useState("");
  const [widgets, setWidgets] = useState([]);
  const [textSplit, setTextSplit] = useState([]);


  useEffect(() => {
    const username = userData.username ? userData.username : USER_ANONYMOUS;
    logUserAnalyticService(TENANT, username, PAGE_VIEW_ACTION, PAGE_ID_KEY, SEARCH_VALUE, userToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSearchContentCallback = (response) => {
    const widgets = response?.data;
    setWidgets(widgets);
    dispatch(turnOffLoadingActionCreator());
    if (updateWidgets) {
      updateWidgets(widgets);
    }
  };

  const filter = (event) => {
    if (event.target !== undefined) {
      setText(event.target.value);
      dispatch(saveSearchTextActionCreator(event.target.value));

      if (updateText) {
        updateText(event.target.value);
      }

      if (event.target.value.length > SEARCH_MIN_CHARS) {
        dispatch(turnOnLoadingActionCreator());
        getSearchContentsService(
          DEFAULT_SEARCH_SORTING,
          DEFAULT_SEARCH_PAGE,
          DEFAULT_SEARCH_LIMIT,
          event.target.value,
          getSearchContentCallback,
        );
      }

      setTextSplit(event.target.value.split(""));
    }
  };

  const ariaProps = {
    inputProps: {
      "aria-label": t("global.button.go.to.player"),
    },
  };

  const handleInputChange = (data) => {
    if (data.length > SEARCH_MIN_CHARS) {
      dispatch(turnOnLoadingActionCreator());
      getSearchContentsService(
        DEFAULT_SEARCH_SORTING,
        DEFAULT_SEARCH_PAGE,
        DEFAULT_SEARCH_LIMIT,
        data,
        getSearchContentCallback,
      );
    }

    setText(data);

    if (updateText) {
      updateText(data);
    }

    setTextSplit(data.split(""));
  };

  return (
    <div className={"Barra-Busqueda"}>
      <SearchContext.Consumer>
        {(searchContext) => {
          setText(searchContext.text ? searchContext.text : "");
        }}
      </SearchContext.Consumer>

      <Autocomplete
        disablePortal
        id="suggestions-autocomplete"
        options={[]}
        sx={{ width: 300 }}
        closeIcon={false}
        popupIcon={false}
        freeSolo
        disabled={passwordMode}
        noOptionsText={t("search.results.text")}
        onInputChange={(ev, text) => handleInputChange(text)}
        renderInput={(params) => (
          <div className={"SearchContainer"}>
            <div className={"IconSearch"}>
              <ion-icon name="search-outline" />
            </div>
            <TextField
              id="searchBarTextField"
              className="InputNome"
              value={text}
              inputRef={(input) => input && (focus ? input.focus() : input.blur())}
              onKeyDown={cancel}
              {...params}
              inputProps={{
                ...params.inputProps,
                'aria-label': t("global.button.go.to.player")
              }}
              placeholder={t("search.placeholder")}
              onChange={(event) => filter(event)}
              {...ariaProps}
            />
          </div>
        )}
      />

      <SearchProvider arrayResult={widgets} text={text} error={error} />
      {textSplit.length >= TEXT_SPLIT_LENGTH && <Redirect to={`${URL_SEARCH}`} />}
    </div>
  );
};

export default SearchBar;

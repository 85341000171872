import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./ExpiredSessionModalComponent.css";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { hideSessionExpiredModalActionCreator, turnOffLoadingActionCreator } from "../../actions/commonActions";
import {removeTokenFromCookie} from "../../Utils/utils";
import { MULTITENANT_CONSTANTS, TENANT } from "../../Resources/Multitenant/tenantConstants";

const ExpiredSessionModalComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const isLoginRequired = MULTITENANT_CONSTANTS[TENANT].showLoginButton;

  const turnOffLoading = useCallback(() => {
    dispatch(turnOffLoadingActionCreator());
  }, [dispatch]);

  const handleRedirectToLogin = () => {
    if (isLoginRequired) {
      dispatch(hideSessionExpiredModalActionCreator());
      removeTokenFromCookie();
      keycloak?.logout();
    }
  };

  useEffect(() => {
    if (isLoginRequired) {
      turnOffLoading();
      localStorage.removeItem("auth");
    }
  }, [isLoginRequired, turnOffLoading]);

  if (!isLoginRequired) {
    return null;
  }

  return (
    <div className="SessionModalBackground">
      <div className="SessionModal">
        <div className={"LoginTextContainer"}>
          <p className={"SessionTitle"}>{t("user.session.expired")}</p>
          <p>{t("user.login.again")}</p>
        </div>
        <div className="LoginButtonContainer">
          <button onClick={handleRedirectToLogin} className="button-focused LoginModalButton">
            {t("user.login")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExpiredSessionModalComponent;

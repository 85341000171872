import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import AlertNotification from "../../../../app/src/Components/GUI/Alert/Alert";
import {URL_VIDEODETAIL} from "../../../../app/src/Components/Routes/Location";
import {
  getCatalogueDataService,
  getChildrenNodesService,
  getRootNodes,
} from "../../../../app/src/services/services";
import { TENANT } from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {
  TEXT_NAME_BEGINNING,
  DEFAULT_CONTENT_LIMIT,
  DEFAULT_PAGE,
  catalogueSortingOptions,
} from "../../../CatalogueConstants";
import {
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import SectionsMenuComponent from "./components/SectionsMenuComponent";
import SubsectionsMenuComponent from "./components/SubsectionsMenuComponent";
import SortMenuComponent from "./components/SortMenuComponent";
import NoContentComponent from "./components/NoContentComponent";
import ContentComponent from "./components/ContentComponent";
import "./Catalogo.css";
import "react-multi-carousel/lib/styles.css";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import { hasContentChildren, hasNoContentChildren } from "./utils/utils";
import { getTranslatedCategory } from "../../../../app/src/Utils/utils";
import keycloak from "../../../../app/src/Utils/keycloak";

const Catalogo = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { scrollRef } = props;

  const [error, setError] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [nameOrder, setNameOrder] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [showNoContent, setShowNoContent] = useState(true);
  const [defaultNode, setDefaultNode] = useState({});
  const [catalogueData, setCatalogueData] = useState({});
  const [rootNodes, setRootNodes] = useState();
  const [nodesChildren, setNodesChildren] = useState([]);
  const [defaultNodeChildren, setDefaultNodeChildren] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [actualContentData, setActualContentData] = useState(null);
  const [defaultSortingOption, setDefaultSortingOption] = useState(
    catalogueSortingOptions.mostRecentFirst.param
  );

  const filteredCatalogueData = catalogueData?.contents?.filter(
    (content) => hasNoContentChildren(content) || hasContentChildren(content)
  );
  const savedNode = useRef(null);

  const getCatalogueDataCallback = (response) => {
    if (response?.data) {
      turnOffLoading();
      const formattedModel = {};
      setActualContentData(response);

      if (parseInt(response?.page) !== DEFAULT_PAGE) {
        formattedModel.contents = [...catalogueData.contents, ...response.data];
      } else {
        formattedModel.contents = response?.data;
      }

      setCatalogueData(formattedModel);
    }
  };

  useEffect(() => {
    if (actualContentData) {
      setHasMore(
        catalogueData?.contents?.length < actualContentData?.meta?.filter_count
      );
    }
  }, [catalogueData]);

  const totalItemsPerPage = 8;
  useEffect(() => {
    if (
      filteredCatalogueData?.length <= totalItemsPerPage &&
      hasMore &&
      actualContentData?.data.length > 0
    ) {
      fetchMoreData();
    }
  }, [hasMore, catalogueData]);

  const ErrorCallback = (err) => {
    turnOffLoading();
    setError(err);
  };

  const getRootNodesCallback = (response) => {
    // Filtramos los nodos para que no nos aparezcan en el selector algunos nodos que comparten la misma categoria/tipo pero que no son nodos raiz.
    const filteredNodes = response?.data.filter((node) => !node?.parent);
    const selectedNode = filteredNodes[0];

    setRootNodes(filteredNodes);
    setDefaultNode(selectedNode);
    savedNode.current = selectedNode;

    getCatalogueDataService(
      TENANT,
      selectedNode?.id,
      getCatalogueDataCallback,
      DEFAULT_CONTENT_LIMIT,
      defaultSortingOption,
      DEFAULT_PAGE,
      ErrorCallback
    );
  };

  useEffect(() => {
    setShowMenu(false);
    setNameOrder("catalogue.most_recent_first");

    turnOnLoading();
    getRootNodes(TENANT, getRootNodesCallback);
  }, []);

  const getChildrenNodesCallback = (response) => {
    const selectedNodeChildren = response?.data[0];

    setDefaultNodeChildren(selectedNodeChildren);
    setNodesChildren(response?.data);

    getCatalogueDataService(
      TENANT,
      selectedNodeChildren?.id,
      getCatalogueDataCallback,
      DEFAULT_CONTENT_LIMIT,
      defaultSortingOption,
      DEFAULT_PAGE,
      ErrorCallback
    );
  };

  useEffect(() => {
    if (!!defaultNode?.has_children) {
      getChildrenNodesService(TENANT, defaultNode.id, getChildrenNodesCallback);
    } else {
      setNodesChildren([]);
    }
  }, [defaultNode]);

  const changeShowNoContent = () => {
    setShowNoContent(false);
  };

  useEffect(() => {
    !catalogueData?.contents && setShowNoContent(true);
    if (catalogueData !== null && catalogueData.contents) {
      turnOffLoading();
    }
  }, [catalogueData]);

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
    if (isInitialLoad) {
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      setIsInitialLoad(false);
    }
  };

  const selectNodeParent = (nodeSelected) => {
    turnOnLoading();
    setPage(DEFAULT_PAGE);
    setHasMore(true);
    setNameOrder("catalogue.most_recent_first");
    setDefaultSortingOption(catalogueSortingOptions.mostRecentFirst.param);
    const selectedNode = rootNodes?.find((node) => node.name === nodeSelected);
    setDefaultNode(selectedNode);
    savedNode.current = selectedNode;

    getCatalogueDataService(
      TENANT,
      selectedNode?.id,
      getCatalogueDataCallback,
      DEFAULT_CONTENT_LIMIT,
      catalogueSortingOptions.mostRecentFirst.param,
      DEFAULT_PAGE,
      ErrorCallback
    );
  };

  const selectNodeChildren = (nodeSelected) => {
    turnOnLoading();
    setPage(DEFAULT_PAGE);
    setHasMore(true);
    setNameOrder("catalogue.most_recent_first");
    setDefaultSortingOption(catalogueSortingOptions.mostRecentFirst.param);
    const selectedNode = nodesChildren?.find(
      (node) => node.name === nodeSelected
    );
    setDefaultNodeChildren(selectedNode);

    getCatalogueDataService(
      TENANT,
      selectedNode?.id,
      getCatalogueDataCallback,
      DEFAULT_CONTENT_LIMIT,
      catalogueSortingOptions.mostRecentFirst.param,
      DEFAULT_PAGE,
      ErrorCallback
    );
  };

  const fetchMoreData = () => {
    let selectedNodeId;
    const updatedPage = page + 1;
    setPage(updatedPage);

    if (Object.keys(defaultNodeChildren).length > 0) {
      selectedNodeId = defaultNodeChildren?.id;
    } else {
      if (!!savedNode) {
        selectedNodeId = savedNode.current?.id;
      } else {
        selectedNodeId = defaultNode?.id;
      }
    }

    getCatalogueDataService(
      TENANT,
      selectedNodeId,
      getCatalogueDataCallback,
      DEFAULT_CONTENT_LIMIT,
      defaultSortingOption,
      updatedPage,
      ErrorCallback
    );
  };

  const displayMenu = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  const handleClickOrder = (name, param) => {
    let selectedNodeId;
    const actualCatalogueLimit = catalogueData?.contents.length;
    setDefaultSortingOption(param);

    if (Object.keys(defaultNodeChildren).length > 0) {
      selectedNodeId = defaultNodeChildren?.id;
    } else {
      selectedNodeId = defaultNode?.id;
    }

    turnOnLoading();

    getCatalogueDataService(
      TENANT,
      selectedNodeId,
      getCatalogueDataCallback,
      actualCatalogueLimit,
      param,
      DEFAULT_PAGE,
      ErrorCallback
    );
    setNameOrder(TEXT_NAME_BEGINNING + name);
    setShowMenu(false);
  };

  // Gestión del cerrado del menu de sorting al hacer click fuera de él
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu && !event.target.closest("#sortMenu")) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  const getTextSort = (id) => {
    return t("catalogue." + id);
  };

  const handleGoToVideoDetail = (id) => {
    history?.push(URL_VIDEODETAIL + "/" + id);
  };

  useEffect(() => {
    if (catalogueData !== null && catalogueData.contents) {
      turnOffLoading();
    }
  }, [catalogueData]);

  return (
    <>
      {catalogueData !== null && catalogueData?.contents && (
        <div id="CatalogoContainer" className="Catalogo">
          <div>
            <div className="Discover">{t("catalogue.discover")}</div>
            {defaultNode && (
              <div className="SelectsContainer">
                {rootNodes?.length > 0 && (
                  <SectionsMenuComponent
                    sections={rootNodes}
                    defaultSections={[defaultNode.name]}
                    selectNodeParent={selectNodeParent}
                  />
                )}
                {nodesChildren?.length > 0 && (
                  <SubsectionsMenuComponent
                    section={defaultNode}
                    defaultSections={nodesChildren}
                    selectNodeChildren={selectNodeChildren}
                  />
                )}

                {!showNoContent && (
                  <SortMenuComponent
                    displayMenu={displayMenu}
                    nameOrder={nameOrder}
                    showMenu={showMenu}
                    sortingOptions={catalogueSortingOptions}
                    handleClickOrder={handleClickOrder}
                    getTextSort={getTextSort}
                  />
                )}
              </div>
            )}
            {!!catalogueData?.contents && (
              <InfiniteScroll
                dataLength={filteredCatalogueData?.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>{t("global.loading")}</h4>}
                scrollableTarget="root"
              >
                <div className="CatalogueVideo">
                  <div className="galleryGrid">
                    <ContentComponent
                      contents={catalogueData?.contents}
                      handleGoToVideoDetail={handleGoToVideoDetail}
                      changeShowNoContent={changeShowNoContent}
                      showNoContent={showNoContent}
                      category={getTranslatedCategory(savedNode)}
                    />
                  </div>
                </div>
              </InfiniteScroll>
            )}
          </div>
          {showNoContent && <NoContentComponent />}
          {error && <AlertNotification />}
        </div>
      )}
    </>
  );
};

export default WithAuthorization(Catalogo);

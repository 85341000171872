import React, { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";

import { GlobalLoadingComponent } from "../Components/Loader/GlobalLoadingComponent";
import ErrorPage from "../Pages/Error/ErrorPage";
import { ARRAY_URLS_OPEN_PAGES, URL_HOME, URL_SECTION } from "../Components/Routes/Location";
import { MULTITENANT_CONSTANTS, TENANT } from "../Resources/Multitenant/tenantConstants";
import { useLocation } from "react-router-dom";

const withAuthorization = (Component) => {
  const isPublicPage = () => {
    const pathname = document.location.pathname;

    if (pathname.includes(URL_SECTION)) {
      return true;
    } else {
      for (const page of ARRAY_URLS_OPEN_PAGES) {
        if (pathname.indexOf(page) !== -1) {
          return true;
        }
      }
    }

    return false;
  };

  const isUnknownRoute = () => {
    const knownRoutes = [...ARRAY_URLS_OPEN_PAGES, URL_HOME, URL_SECTION];
    return !knownRoutes.some((route) => window.location.pathname.startsWith(route));
  };

  return (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const localUserGuest = searchParams.get("guest") === "true";

    const { keycloak, initialized } = useKeycloak();
    const [isKeycloakInitialized, setIsKeycloakInitialized] = useState(false);

    const isPublic = isPublicPage();
    const isLoginRequired = MULTITENANT_CONSTANTS[TENANT].showLoginButton;

    useEffect(() => {
      if (initialized) {
        setIsKeycloakInitialized(true);
      }
    }, [initialized]);

    if (isUnknownRoute()) {
      if (!isLoginRequired) {
        window.location.href = URL_HOME;
        return null;
      }
    }

    if (
      (isKeycloakInitialized && keycloak?.authenticated) ||
      (localUserGuest && window.location.pathname === URL_HOME) ||
      isPublic
    ) {
      return <Component {...props} />;
    }

    if (isKeycloakInitialized && !keycloak.authenticated && !isPublic && !localUserGuest) {
      keycloak.login({ locale: MULTITENANT_CONSTANTS[TENANT]?.defaultLocale });
      return <GlobalLoadingComponent />;
    }

    if (!isKeycloakInitialized) {
      return <GlobalLoadingComponent />;
    }

    return <ErrorPage />;
  };
};

export default withAuthorization;

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GalleryCardComponent from "../../../app/src/Components/common/GalleryCardComponent";
import { IMAGE_HEIGHT, IMAGE_WIDTH } from "../../CatalogueConstants";
import {URL_VIDEODETAIL} from "../../../app/src/Components/Routes/Location";
import keycloak from "../../../app/src/Utils/keycloak";

const ContentCardComponent = ({ content, handleSelectedMedia, imageCard }) => {
  const { t } = useTranslation();

  return (
    <div
      className="PlaylistItemContainer VideoContainer"
      key={content.id}
      onClick={() => handleSelectedMedia(content.id)}
    >
      <GalleryCardComponent
        image={imageCard}
        width={IMAGE_WIDTH}
        height={IMAGE_HEIGHT}
        contentType={content.type?.name}
        tags={content.tags}
      />
      <div className="contentFooter">
        <div className="PlaylistItemTitle-Default">{content.title}</div>

        <div className="PlayFavContainer">
          <div className="boton-play">
            <Link
              className="BotonVer"
              to={{
                pathname: URL_VIDEODETAIL + "/" + content.id,
              }}
            >
              {t("ficha.seeDetail")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentCardComponent;

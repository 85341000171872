import {
  DISPLAY_SESSION_EXPIRED_MODAL,
  HIDE_SESSION_EXPIRED_MODAL,
  TURN_OFF_LOADING_ACTION,
  TURN_ON_LOADING_ACTION,
  LOAD_TENANT_STYLES,
  STORE_PRODUCT_URL,
  TOGGLE_PASSWORD_MODE,
  DISPLAY_ON_BOARDING,
  SELECT_MENU_SECTION,
  SELECT_MENU_SCREEN_ID,
  SECTIONS_MENU,
  SAVE_USER_DATA,
  SAVE_TENANT_LANGUAGES,
  SAVE_SEARCH_TEXT,
  SAVE_SPONSORS,
  SAVE_INTERFACE_URLS,
  SAVE_LEGAL,
  SELECTED_SECTION,
  SAVE_RULES,
  CONTENT_HASH,
  CONTENT_TYPE,
  USER_URL_AVATAR,
  SAVE_LANGUAGE_SELECTED, SAVE_TENANT_SHOW_PREVIEWS,
  USER_LOGIN,
} from "../actions/actionTypes";
import { LOCAL_STORAGE_KEY__SKIP_ONBOARDING } from "../Utils/Constants";

const skipOnBoarding = localStorage.getItem(LOCAL_STORAGE_KEY__SKIP_ONBOARDING);

const INITIAL_STATE = {
  loading: true,
  isSessionExpired: false,
  tenantStyles: {},
  productURL: null,
  passwordMode: false,
  onBoarding: JSON.parse(skipOnBoarding),
  sectionSelected: null,
  menuSections: [],
  userData: {},
  userLogin: false,
  tenantLanguages: [],
  searchText: "",
  sponsors: [],
  interfaceUrls: {},
  legal: null,
  rules: null,
  contentHash: null,
  selectedScreenId: null,
  selectedSection: null,
  contentType: null,
  userURLAvatar: null,
  previews: false,
};

function commonState(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TURN_ON_LOADING_ACTION: {
      return { ...state, loading: true };
    }
    case TURN_OFF_LOADING_ACTION: {
      return { ...state, loading: false };
    }
    case DISPLAY_SESSION_EXPIRED_MODAL: {
      return { ...state, isSessionExpired: true };
    }
    case HIDE_SESSION_EXPIRED_MODAL: {
      return { ...state, isSessionExpired: false };
    }
    case LOAD_TENANT_STYLES: {
      return { ...state, tenantStyles: action.data };
    }
    case STORE_PRODUCT_URL: {
      return { ...state, productURL: action.data };
    }
    case TOGGLE_PASSWORD_MODE: {
      return { ...state, passwordMode: !state.passwordMode };
    }
    case DISPLAY_ON_BOARDING: {
      return { ...state, onBoarding: action.data };
    }
    case SELECT_MENU_SECTION: {
      return { ...state, sectionSelected: action.data };
    }
    case SELECT_MENU_SCREEN_ID: {
      return { ...state, selectedScreenId: action.data };
    }
    case SELECTED_SECTION: {
      return { ...state, selectedSection: action.data };
    }
    case SECTIONS_MENU: {
      return { ...state, sectionsMenu: action.data };
    }
    case SAVE_USER_DATA: {
      return { ...state, userData: action.data };
    }
    case USER_LOGIN: {
      return { ...state, userLogin: action.data };
    }
    case SAVE_TENANT_LANGUAGES: {
      return { ...state, tenantLanguages: action.data };
    }
    case SAVE_TENANT_SHOW_PREVIEWS: {
        return { ...state, previews: action.data };
    }
    case SAVE_SEARCH_TEXT: {
      return { ...state, searchText: action.data };
    }
    case SAVE_SPONSORS: {
      return { ...state, sponsors: action.data };
    }
    case SAVE_INTERFACE_URLS: {
      return { ...state, interfaceUrls: action.data };
    }
    case SAVE_LEGAL: {
      return { ...state, legal: action.data };
    }
    case SAVE_RULES: {
      return { ...state, rules: action.data };
    }
    case CONTENT_HASH: {
      return { ...state, contentHash: action.data };
    }
    case CONTENT_TYPE: {
      return { ...state, contentType: action.data };
    }
    case USER_URL_AVATAR: {
      return { ...state, userURLAvatar: action.data };
    }
    case SAVE_LANGUAGE_SELECTED: {
      return { ...state, languageSelected: action.data };
    }
    default:
      return state;
  }
}

export default commonState;

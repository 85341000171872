import React, { useEffect, useState } from "react";
import CarouselAuto from "react-bootstrap/Carousel";
import "react-multi-carousel/lib/styles.css";
import Spinner from "react-bootstrap/Spinner";

import "@detail/Pages/DetailPage/CarrouselStyle.css";
import HighlightSlideComponent from "./HighlightSlideComponent";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../../app/src/Resources/Multitenant/tenantConstants";
import { SWITCHER_WIDTH } from "../../../../../app/src/Utils/Constants";

const HighlightWidgetComponent = ({
  playlist,
  rotateTime,
  setRef,
  getRef,
  defaultBanner,
  trigger,
  clickCallback,
  playSpinner,
}) => {
  const [renderMobileVersion, setRenderMobileVersion] = useState(false);

  const showIndicators = () => {
    return MULTITENANT_CONSTANTS[TENANT].showSlideHighlightIndicators;
  };
  const showControls = () => {
    return !renderMobileVersion || MULTITENANT_CONSTANTS[TENANT].showControlsHighlightedComponent;
  }
  const handleResize = () => {
    setRenderMobileVersion(window.innerWidth <= SWITCHER_WIDTH);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="CarouselContainer Principal">
        {!!playlist?.length && (
          <CarouselAuto
            controls={showControls()}
            interval={rotateTime}
            indicators={showIndicators()}
          >
            {playlist?.map((w) => (
              <CarouselAuto.Item key={w.id}>
                <HighlightSlideComponent
                  w={w}
                  setRef={setRef}
                  getRef={getRef}
                  defaultBanner={defaultBanner}
                  trigger={trigger}
                  clickCallback={clickCallback}
                  renderMobileVersion={renderMobileVersion}
                />
              </CarouselAuto.Item>
            ))}
          </CarouselAuto>
        )}
        {playSpinner && (
          <div className="SpinnerContainer">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
    </>
  );
};

export default HighlightWidgetComponent;
